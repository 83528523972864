
import './App.css';
import { BrowserRouter as Router, Routes, Route,  } from "react-router-dom";

import Navbar from './components/Navbar';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Footer from './components/Footer';
import ContactUs from './pages/ContactUs';
import Services from './pages/Services';
// import LiveService from './pages/LiveService';
// import Loader from './components/Loader';
// import StaticPolicy from './pages/StaticPolicy';
import QueryList from './pages/QueryList';
import LogIn from './pages/LogIn';
import Register from './pages/Register';
import NotFound from './pages/NotFound';
import LogoutPage from './components/LogoutPage';
import FeedbackPage from './pages/FeedbackPage';
import ForgotPass from './pages/ForgotPass';
import PasswordReset from './pages/PasswordReset';
import ChangePassword from './pages/ChangePassword';


function App() {
  const isLoggedIn = Boolean(localStorage.getItem("token"));

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };
  return (
    <Router>
      {/* <Loader/> */}
      <Navbar loggedIn={isLoggedIn} onLogout={handleLogout}/>
      <Routes>
      
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        {/* <Route path="/livevideo" element={<LiveService />} /> */}
        {/* <Route path="/static_policy" element={<StaticPolicy />} /> */}
        <Route path="/bike_service" element={<QueryList />} />
        <Route path="/feedback" element={<FeedbackPage />} />
        <Route path="/admin_login" element={<LogIn />} />
        <Route path="/admin_logout" element={<LogoutPage />} />
        <Route path="/forgot_password" element={<ForgotPass/>} />
        <Route path="/password_reset" element={<PasswordReset/>} />
        <Route path="/change_password" element={<ChangePassword/>} />
        <Route path="/admin_register" element={<Register />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>


  );
}

export default App;
