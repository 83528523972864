import React from 'react';
import { Link } from 'react-router-dom';

const WhatsappButton = () => {
  return (
    <Link to="https://api.whatsapp.com/send?phone=919595619198" className="whatsapp-button" target="_blank" rel="noopener noreferrer" >
      <i className="bx bx-lg bxl-whatsapp"></i>
    </Link>
  );
};

export default WhatsappButton;