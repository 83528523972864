import { useState } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { Button, Form, Modal, Container, Stack } from "react-bootstrap";
import { Alert } from "react-bootstrap";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [bikeModel, setBikeModel] = useState("");
  const [area, setArea] = useState("");
  const [problem, setProblem] = useState("");

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleNumberChange = (event) => {
    const value = event.target.value;
    // remove all non-numeric characters from input using regular expression
    const filteredValue = value.replace(/[^0-9]/g, "");
    setNumber(filteredValue);
  };

  const handleBikeModelChange = (event) => {
    setBikeModel(event.target.value);
  };

  const handleAreaChange = (event) => {
    setArea(event.target.value);
  };

  const handleProblemChange = (event) => {
    setProblem(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // eslint-disable-next-line
      const response = await axios.post(`${API_URL}add_bike_service/`, {
        name: name,
        contact_no: number,
        bike_model: bikeModel,
        area: area,
        bike_problem: problem,
      });
     
      setShowSuccessAlert(true);
      setShowErrorAlert(false);
      setShowModal(false);
    } catch (error) {
   
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 500) {
          setErrorAlertMessage(error.response.statusText);
        } else {
          let errorMessage = "";
          const errorData = error.response.data.data;
          if (errorData.name) {
            errorMessage += `Name: ${errorData.name[0]} `;
          }
          if (errorData.contact_no) {
            errorMessage += `Contact number: ${errorData.contact_no[0]} `;
          }
          if (errorData.bike_model) {
            errorMessage += `Bike model: ${errorData.bike_model[0]} `;
          }
          if (errorData.area) {
            errorMessage += `Area: ${errorData.area[0]} `;
          }
          if (errorData.bike_problem) {
            errorMessage += `Problem: ${errorData.bike_problem[0]} `;
          }
          if (errorMessage) {
            setErrorAlertMessage(errorMessage);
          } else {
            setErrorAlertMessage(`Error ${statusCode}: ${error.response.data.message}`);
          }
        }
      } else if (error.request) {
        setErrorAlertMessage("Unable to make a request to the server.");
      } else {
        setErrorAlertMessage("An unexpected error occurred.");
      }
    }
  };


  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="banner-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-content">
                <span style={{ fontSize: 30 }}>In Service Since 1992</span>
                <h1>Get Your Bike Service Done Now</h1>

                <Container>
                  {showSuccessAlert && (
                    <Alert
                      variant="success"
                      onClose={() => setShowSuccessAlert(false)}
                      dismissible
                    >
                      Your message has been sent successfully.
                    </Alert>
                  )}
                  {showErrorAlert && (
                    <Alert
                      variant="danger"
                      onClose={() => setShowErrorAlert(false)}
                      dismissible
                    >
                      Error: {errorAlertMessage}
                    </Alert>
                  )}
                  <Form>
                    <Stack direction="vertical">
                      <Form.Group
                        controlId="formName"
                        className="col-lg-8 col-sm-8"
                      >
                        {/* <Form.Label>Name</Form.Label> */}
                        <Form.Control
                          type="text"
                          placeholder="Enter name"
                          value={name}
                          onChange={handleNameChange}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="formNumber"
                        className="col-lg-8 col-sm-8 my-3 "
                      >
                        {/* <Form.Label> Phone Number</Form.Label> */}
                        <Form.Control
                          type="character"
                          placeholder="Enter number"
                          value={number}
                          onChange={handleNumberChange}
                          onKeyDown={(event) => {
                            // allow only numeric characters and specific key codes
                            if (
                              !/[0-9]/.test(event.key) &&
                              event.keyCode !== 8 &&
                              event.keyCode !== 9
                            ) {
                              event.preventDefault();
                            }
                          }}
                          required
                        />
                      </Form.Group>
                    </Stack>

                    <Button className="default-btn " style={{border: "none"}}  onClick={handleOpenModal}>
                      Submit
                    </Button>

                    <Modal show={showModal} onHide={handleCloseModal}>
                      {showSuccessAlert && (
                        <Alert
                          variant="success"
                          onClose={() => setShowSuccessAlert(false)}
                          dismissible
                        >
                          Your message has been sent successfully.
                        </Alert>
                      )}
                      {showErrorAlert && (
                        <Alert
                          variant="danger"
                          onClose={() => setShowErrorAlert(false)}
                          dismissible
                        >
                          Error: {errorAlertMessage}
                        </Alert>
                      )}
                      <Modal.Header closeButton>
                        <Modal.Title>Bike Service Request</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <Form.Group controlId="formBikeModel">
                            <Form.Label>Bike Model Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter bike model name"
                              value={bikeModel}
                              onChange={handleBikeModelChange}
                              required
                            />
                          </Form.Group>

                          <Form.Group controlId="formArea" className="my-2">
                            <Form.Label>Area</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter area where bike is"
                              value={area}
                              onChange={handleAreaChange}
                              required
                            />
                          </Form.Group>

                          <Form.Group controlId="formProblem" className="my-2">
                            <Form.Label>Describe the Problem</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              required
                              value={problem}
                              onChange={handleProblemChange}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        {/* <Button variant="secondary" onClick={handleCloseModal}>
                          Close
                        </Button> */}
                        <Button className="default-btn " style={{border: "none"}} onClick={handleSubmit}>
                          Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Form>
                </Container>
              </div>
            </div>
            <div className="col-lg-6 pe-0">
              <div className="banner-img">
                <img src="assets/img/home-one/11.jpg" alt="home page model" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="choose-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="span-bg">Why Choose Us?</span>
            <h2>We Have the Plan for You to Give You Best </h2>
          </div>
          <div className="row pt-45">
            <div className="col-lg-4 col-sm-6">
              <div className="choose-item">
                <div className="choose-item-icon one-bg">
                  <i className="bx bx-help-circle"></i>
                </div>
                <h3>Genuine Bike Service </h3>
                <p>
                  We offer genuine bike service  for ever customer. <br/><br/>
                </p>
                {/* <a href="/#" className="read-more">
                  Read More <i className="bx bx-right-arrow-alt"></i>
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="choose-item">
                <div className="choose-item-icon two-bg">
                  <i className="bx bx-time"></i>
                </div>
                <h3>On-time Deliveries</h3>
                <p>
                  We have the best service for you to make and on-time deliveries is one of
                  the best service.
                 
                </p>
                {/* <a href="/#" className="read-more">
                  Read More <i className="right-icon bx bx-right-arrow-alt"></i>
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
              <div className="choose-item">
                <div className="choose-item-icon three-bg">
                  <i className="bx bx-bulb"></i>
                </div>
                <h3>Services by Experts</h3>
                <p>
                  We have the best Experts for you for your optimized Servicing. <br/><br/>
                </p>
                {/* <a href="/#" className="read-more">
                  Read More <i className="right-icon bx bx-right-arrow-alt"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="counter-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-md-3">
              <div className="single-counter">
                <i className="flaticon-rating icon-color-1"></i>
                <h3>120K+</h3>
                <span>Satisfied Clients</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-3">
              <div className="single-counter">
                <i className="flaticon-settings icon-color-2"></i>
                <h3>200K+</h3>
                <span>Bike Repair</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-3">
              <div className="single-counter">
                <i className="flaticon-user icon-color-3"></i>
                <h3>100+</h3>
                <span>Dedicated Experts</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-3">
              <div className="single-counter">
                <i className="flaticon-calendar icon-color-4"></i>
                <h3>30+</h3>
                <span>Years Experience</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
