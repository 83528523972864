import React from 'react'

import { Link } from 'react-router-dom'

const AboutUs = () => {
  return (
    <div>

{/* 
<div className="preloader">
    <div className="d-table">
        <div className="d-table-cell">
            <div className="spinner"></div>
        </div>
    </div>
</div> */}

<div className="inner-banner inner-bg1">
        <div className="container">
            <div className="inner-title text-center">
                <h3>About Us</h3>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <i className='bx bxs-chevron-right'></i>
                    </li>
                    <li>About Us</li>
                </ul>
            </div>
        </div>
    </div>

    <div className="about-area pt-100 pb-70">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="about-img-2">
                        <img src="assets/img/about/about-img11.jpg" alt="Images"/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="about-content">
                        <div className="section-title">
                            <span className="span-bg">About Us</span>
                            <h2>We Have 30+ Years of Service Experience for You</h2>
                            <p>
                            With over 30 years of experience in the bike servicing industry, our team of experts is dedicated to providing top-notch online bike servicing to our customers. Our convenient and reliable service allows you to easily schedule and manage your bike maintenance from the comfort of your own home. Trust us to keep your bike in peak condition and ready for the road.
                            </p>
                        </div>
                        <ul>
                            <li>
                                <i className='bx bx-check-circle'></i>
                                Customer Satisfaction
                            </li>
                            <li>
                                <i className='bx bx-check-circle'></i>
                                Latest & Modern Shop
                            </li>
                            <li>
                                <i className='bx bx-check-circle'></i>
                                Expertise Diagnostics
                            </li>
                            <li>
                                <i className='bx bx-check-circle'></i>
                                Fair Pricing
                            </li>
                            <li>
                                <i className='bx bx-check-circle'></i>
                                Expert Care
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="choose-area pb-70">
        <div className="container">
            <div className="section-title text-center">
                <span className="span-bg">Why Choose Us?</span>
                <h2>We Have the Plan for You to Give You Best </h2>
            </div>
            <div className="row pt-45">
                <div className="col-lg-4 col-sm-6">
                    <div className="choose-card">
                        <div className="choose-icon one-bg">
                            <i className='bx bx-help-circle'></i>
                        </div>
                        <div className="content">
                            <h3>Problem Solver</h3>
                            <p style={{ textAlign: "justify"}}>At Suvarna Bikes Care, we pride ourselves on our ability to solve any problem your bike may have. Our team of experts has the knowledge and experience to diagnose and fix even the most complex issues, ensuring that your bike is always in top condition. <br/><br/></p>
                            {/* <a href="/#" className="read-more-btn">
                                <i className="left-icon bx bx-right-arrow-alt"></i> Read More <i
                                    className="right-icon bx bx-right-arrow-alt"></i></a> */}
                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="choose-card">
                        <div className="choose-icon two-bg">
                            <i className='bx bx-time'></i>
                        </div>
                        <div className="content">
                            <h3>On-time Deliveries</h3>
                            <p style={{ textAlign: "justify"}}>We understand that your time is valuable, which is why we are committed to delivering our services on time, every time. Our efficient and reliable online bike servicing process ensures that your bike is serviced and returned to you as quickly as possible. <br/><br/></p>
                            {/* <a href="/#" className="read-more-btn">
                                <i className="left-icon bx bx-right-arrow-alt"></i> Read More <i
                                    className="right-icon bx bx-right-arrow-alt"></i></a>
                             */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                    <div className="choose-card">
                        <div className="choose-icon three-bg">
                            <i className='bx bx-bulb'></i>
                        </div>
                        <div className="content">
                            <h3>Services by Experts</h3>
                            <p style={{ textAlign: "justify"}}>At Suvarna Bikes Care, our services are performed by highly skilled and experienced technicians who use only the best tools and equipment. Our team of experts is dedicated to providing the highest level of care for your bike, ensuring that it receives the best possible service.</p>
                            {/* <a href="/#" className="read-more-btn">
                                <i className="left-icon bx bx-right-arrow-alt"></i> Read More <i
                                    className="right-icon bx bx-right-arrow-alt"></i></a>
                             */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    </div>
  )
}

export default AboutUs
