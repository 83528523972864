import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LogIn = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    
    const navigate = useNavigate();
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const response = await axios.post(`${API_URL}login/`, {
            username: email,
            password: password
          });
      
          localStorage.setItem('token', response.data.token);
          navigate('/');
          window.location.reload();
          setSuccess('Login successful!');
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              // handle validation errors for specific fields
              const errorData = error.response.data;
              if (errorData.username) {
                setError(`Username: ${errorData.username}`);
              } else if (errorData.password) {
                setError(`Password: ${errorData.password}`);
              } else {
                setError('Invalid credentials. Please try again.');
              }
            } else if (error.response.status === 500) {
              // handle server errors
              setError('Sorry, there was an error connecting to the server.');
            } else {
              setError('An unexpected error occurred.');
            }
          } else if (error.request) {
            setError('Unable to make a request to the server.');
          } else {
            setError('An unexpected error occurred.');
          }
        }
      };
    
    return (
        <div>
            <div className="inner-banner inner-bg7">
                <div className="container">
                    <div className="inner-title text-center">
                        <h3> Login </h3>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className='bx bxs-chevron-right'></i>
                            </li>
                            <li> Login </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className="sign-in-area pt-100 pb-70">
                <div className="container">
                    <div className="row pt-45">
                        <div className="col-lg-12">
                            <div className="user-all-form">
                                <div className="contact-form">
                                    <div className="section-title text-center">
                                        <span className="span-bg">Login</span>
                                        <h2>Login to Your Account!</h2>
                                    </div>
                                    <form id="contactForm" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12 ">
                                                <div className="form-group">
                                                    <input type="text" name="email"
                                                        id="email"
                                                        className="form-control"
                                                        required
                                                        value={email}
                                                        onChange={(event) => setEmail(event.target.value)}
                                                        data-error="Please enter your  Email"
                                                        placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input className="form-control" type="password" name="password"
                                                        id="password"
                                                        required
                                                        value={password}
                                                        placeholder="Password"
                                                        onChange={(event) => setPassword(event.target.value)} />
                                                </div>
                                                {error && <div className="alert alert-danger">{error}</div>}
                                                {success && <div className="alert alert-success">{success}</div>}
                                            </div>
                                            <div className="col-lg-6 col-sm-6 form-condition">
                                                {/* <div className="agree-label">
                                                    <input type="checkbox" id="chb1" />
                                                    <label htmlFor="chb1">
                                                        Remember Me
                                                    </label>
                                                </div> */}
                                            </div>
                                            <div className="col-lg-6 col-sm-6">
                                                <Link  className="forget" to="/forgot_password">Forgot my password?</Link>
                                            </div>
                                            <div className="col-lg-12 col-md-12 text-center">
                                                <button type="submit" className="default-btn">
                                                    Login Now
                                                </button>
                                            </div>
                                            <div className="col-12">
                                                <p className="account-desc">
                                                    Not a member?&nbsp;
                                                    <Link to="/admin_register">Create Account</Link>
                                                </p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LogIn
