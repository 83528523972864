import { useNavigation } from "react-router-dom";

const LogoutPage = () => {
  const navigation = useNavigation();

  const handleLogout = () => {
    // Clear the user's token from local storage or cookies
    localStorage.removeItem("token");
    // Redirect the user to the login page
    navigation.navigate("/admin_login");
  };

  return (
    <div>
      <h1>Logout</h1>
      <p>Are you sure you want to logout?</p>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default LogoutPage;