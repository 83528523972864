import React from "react";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { Link } from "react-router-dom";
import WhatsappButton from "../components/WhatsappButton";
import { Alert } from "react-bootstrap";

const ContactUs = () => {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState("");

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleNumberChange = (event) => {
        const value = event.target.value;
        // remove all non-numeric characters from input using regular expression
        const filteredValue = value.replace(/[^0-9]/g, "");
        setNumber(filteredValue);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // eslint-disable-next-line
          const response = await axios.post(`${API_URL}add_feed/`, {
            name: name,
            contact_no: number,
            email: email,
            message: message,
          });
          setShowSuccessAlert(true);
          setShowErrorAlert(false);
        } catch (error) {
        
          setShowSuccessAlert(false);
          setShowErrorAlert(true);
          if (error.response) {
            const statusCode = error.response.status;
            if (statusCode === 500) {
              setErrorAlertMessage(error.response.statusText);
            } else {
              let errorMessage = "";
              const errorData = error.response.data.data;
              if (errorData.name) {
                errorMessage += `Name: ${errorData.name[0]} `;
              }
              if (errorData.contact_no) {
                errorMessage += `Contact number: ${errorData.contact_no[0]} `;
              }
              if (errorData.email) {
                errorMessage += `Email: ${errorData.email[0]} `;
              }
              if (errorData.message) {
                  errorMessage += `Message: ${errorData.message[0]} `;
              }
              if (errorMessage) {
                setErrorAlertMessage(errorMessage);
              } else {
                setErrorAlertMessage(`Error ${statusCode}: ${error.response.data.message}`);
              }
            }
          } else if (error.request) {
            setErrorAlertMessage("Unable to make a request to the server.");
          } else {
            setErrorAlertMessage("An unexpected error occurred.");
          }
        }
    };

    return (
        <div>
            <WhatsappButton />
            <div className="inner-banner inner-bg13">
                <div className="container">
                    <div className="inner-title text-center">
                        <h3>Contact Us</h3>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="bx bxs-chevron-right"></i>
                            </li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="contact-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contact-form">
                                <div className="section-title">
                                    <span className="span-bg">CONTACT</span>
                                    <h2>Keep in Touch</h2>
                                    <p >
                                    Need help or have a question? Get in touch with Suvarna Bikes Care. Our expert team is dedicated to providing top-notch customer service and support.
                                    </p>
                                </div>
                                {showSuccessAlert && (
                                    <Alert
                                        variant="success"
                                        onClose={() => setShowSuccessAlert(false)}
                                        dismissible
                                    >
                                        Your message has been sent successfully.
                                    </Alert>
                                )}
                                {showErrorAlert && (
                                    <Alert
                                        variant="danger"
                                        onClose={() => setShowErrorAlert(false)}
                                        dismissible
                                    >
                                        Error: {errorAlertMessage}
                                    </Alert>
                                )}
                                <form id="contactForm">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="form-control"
                                                    value={name}
                                                    onChange={handleNameChange}
                                                    required
                                                    data-error="Please enter your name"
                                                    placeholder="Name"
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-sm-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="phone_number"
                                                    id="phone_number"
                                                    value={number}
                                                    onChange={handleNumberChange}
                                                    onKeyDown={(event) => {
                                                        // allow only numeric characters and specific key codes
                                                        if (
                                                            !/[0-9]/.test(event.key) &&
                                                            event.keyCode !== 8 &&
                                                            event.keyCode !== 9
                                                        ) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    required
                                                    data-error="Please enter your number"
                                                    className="form-control"
                                                    placeholder="Phone"
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                    required
                                                    data-error="Please enter your email"
                                                    placeholder="Email"
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea
                                                    name="message"
                                                    className="form-control"
                                                    id="message"
                                                    cols="30"
                                                    rows="8"
                                                    value={message}
                                                    onChange={handleMessageChange}
                                                    required
                                                    data-error="Write your message"
                                                    placeholder="Your Message"
                                                ></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <button
                                                type="submit"
                                                className="default-btn"
                                                onClick={handleSubmit}
                                            >
                                                Send Message
                                            </button>
                                            <div
                                                id="msgSubmit"
                                                className="h3 text-center hidden"
                                            ></div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contact-sidebar">
                                <h2>Our Contact Details</h2>
                                <p>
                                Get in touch with Suvarna Bikes Care for all your bike servicing needs. Our team of experts is always here to help.
                                </p>
                                <div className="row">
                                    <div className="col-lg-12 col-md-4">
                                        <div className="contact-card">
                                            <i className="bx bx-home-smile"></i>
                                            <div className="content">
                                                <h3>Address</h3>
                                                
                                                <p><a href="https://goo.gl/maps/V9HmREaQEeWJo31g7" target="_blank" rel="noreferrer">
                                                    Suvarna Bikes Care trimurty chowk, Bus stop, Plot 45,
                                                    Sopan Bhau Kadam chowk, Durganagar, Nashik,
                                                    Maharashtra 422009</a>
                                                </p>
                                                {/* <span></span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-4">
                                        <div className="contact-card">
                                            <i className="bx bx-phone-call"></i>
                                            <div className="content">
                                                <h3>Phone</h3>
                                                <p>
                                                    <Link to="tel:+919595619198">+919595619198</Link>
                                                </p>
                                                {/* <span>
                                                    <Link to="tel:+215-123-8597">+215-123-8597</Link>
                                                </span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-4">
                                        <div className="contact-card">
                                            <i className="bx bxs-envelope"></i>
                                            <div className="content">
                                                <h3>Email</h3>
                                                <p>
                                                    <Link to="mailto:suvarnabikes@gmail.com">
                                                        <span
                                                            className="__cf_email__"
                                                            data-cfemail="7a13141c153a1f00130854191517"
                                                        >
                                                            suvarnabikes@gmail.com
                                                        </span>
                                                    </Link>
                                                </p>
                                                {/* <span>
                                                    <Link to="https://templates.hibootstrap.com/cdn-cgi/l/email-protection#3058555c5c5f70554a59421e535f5d">
                                                        <span
                                                            className="__cf_email__"
                                                            data-cfemail="4e262b2222210e2b34273c602d2123"
                                                        >
                                                            [email&#160;protected]
                                                        </span>
                                                    </Link>
                                                </span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="map-area">
                <div className="container-fluid m-0 p-0">
                    <iframe
                        title="SuvernaBikeCare"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119986.68866472767!2d73.6865982579418!3d19.98398885029343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb5420760139%3A0x903bbab7a9a0df92!2sSuvarna%20Bikes%20Care!5e0!3m2!1sen!2sin!4v1678192235441!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        frameBorder="0"
                        style={{ border: "0" }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
