
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {

  return (
    <div>
      <div className="inner-banner inner-bg7">
        <div className="container">
          <div className="inner-title text-center">
            <h3> 404 Error Page! </h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="bx bxs-chevron-right"></i>
              </li>
              <li> Page Not Found  </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="error-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="error-content">
              <h1>
                4 <span>0</span> 4
              </h1>
              <h3>Oops! Page Not Found</h3>
              <p>
                The page you are looking for might have been removed had its
                name changed or is temporarily unavailable.
              </p>
              <Link to="/" className="default-btn default-hot-toddy">
                Return To Home Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
