import React, { Component } from "react";
import { API_URL } from "../config";
import { Card, Row, Col, Container } from "react-bootstrap";
import axios from "axios";


class QueryList extends Component {
  state = {
    users: [],
    total: null,
    per_page: null,
    current_page: localStorage.getItem("currentPage") || 1,
  };

  componentDidMount() {
    const { current_page } = this.state;
    this.makeHttpRequestWithPage(current_page);
  }

  componentDidUpdate(prevProps, prevState) {
    const { current_page } = this.state;
    if (prevState.current_page !== current_page) {
      this.makeHttpRequestWithPage(current_page);
    }
  }

  makeHttpRequestWithPage = async (pageNumber) => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/admin_login";
      return;
    }

    try {
      const response = await axios.get(`${API_URL}bike_service/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          page: pageNumber,
        },
      });

      const { count, results } = response.data.data;
      // eslint-disable-next-line
      const per_page = results.length;

      this.setState({
        users: results,
        total: count,
        per_page: results.length,
        current_page: pageNumber,
      });

      localStorage.setItem("currentPage", pageNumber);
    } catch (error) {
  
    }
  };

  handlePageClick = (page) => {
    this.setState({ current_page: page });
  };

  render() {
    const { users, total, per_page, current_page } = this.state;

    const totalPages = Math.ceil(total / per_page);

    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
      {/* <div className="container">
      <h2> Bike Service Enquiry</h2>
      </div> */}
        
          <div className="container pt-20 mb-5">
            <div className="inner-title text-center">
              <h3> Bike Service Enquiry </h3>
            </div>
          </div>
        
        <Container className="choose-area pt-20 pb-70">
          <Row className="d-flex justify-content-center">
            {users && users.length > 0 ? (
              users.map((user) => (
                <Col key={user.id} xs={12} md={6} lg={6}>
                  <Card className="mb-5 d-flex shadow p-3 mb-5 bg-white rounded">
                    <Card.Body>
                      <Row>
                        <Col xs={4}>
                          <Card.Title style={{ fontSize: "1.1rem" }}>
                            Id:
                          </Card.Title>
                        </Col>
                        <Col xs={8}>
                          <Card.Text>{user.id}</Card.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Card.Title style={{ fontSize: "1.1rem" }}>
                            Name:
                          </Card.Title>
                        </Col>
                        <Col xs={8}>
                          <Card.Text>{user.name}</Card.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Card.Title style={{ fontSize: "1.1rem" }}>
                            Phone:
                          </Card.Title>
                        </Col>
                        <Col xs={8}>
                          <Card.Text>
                            <a href={`tel:${user.contact_no}`}>
                              {user.contact_no}
                            </a>
                          </Card.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Card.Title style={{ fontSize: "1.1rem" }}>
                            area:
                          </Card.Title>
                        </Col>
                        <Col xs={8}>
                          <Card.Text>{user.area}</Card.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Card.Title style={{ fontSize: "1.1rem" }}>
                            Problem:
                          </Card.Title>
                        </Col>
                        <Col xs={8}>
                          <Card.Text>{user.bike_problem}</Card.Text>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <Col xs={12} md={6} lg={6}>
                <Card className="mb-5 d-flex shadow p-3 mb-5 bg-white rounded">
                  <Card.Body>
                    <Row>
                      <Col xs={12}>
                        <Card.Title
                          className="text-center"
                          style={{ fontSize: "1.1rem" }}
                        >
                          There is no data available to display.
                        </Card.Title>
                      </Col>
                      {/* <Col xs={8}>
                <Card.Text>{user.id}</Card.Text>
              </Col> */}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>

          <div className="d-flex justify-content-center">
            <nav>
              <ul className="pagination">
                <li
                  className={`page-item ${
                    current_page === 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => this.makeHttpRequestWithPage(1)}
                  >
                    First
                  </button>
                </li>
                <li
                  className={`page-item ${
                    current_page === 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() =>
                      this.makeHttpRequestWithPage(current_page - 1)
                    }
                  >
                    Previous
                  </button>
                </li>

                {/* {pageNumbers.map((number) => (
                  <li
                    key={number}
                    className={`page-item ${current_page === number ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => this.handlePageClick(number)}
                    >
                      {number}
                    </button>
                  </li>
                ))} */}

                <li
                  className={`page-item ${
                    current_page === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() =>
                      this.makeHttpRequestWithPage(current_page + 1)
                    }
                  >
                    Next
                  </button>
                </li>
                <li
                  className={`page-item ${
                    current_page === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => this.makeHttpRequestWithPage(totalPages)}
                  >
                    Last
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <p className="text-center">
            Showing page {current_page}
            {/* of {totalPages} */}
          </p>
        </Container>
      </div>
    );
  }
}

export default QueryList;
