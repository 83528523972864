import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../config";
import { Link } from "react-router-dom";

const PasswordReset = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { uidb64, token } = useParams();

    useEffect(() => {
        // Verify reset link on component mount
        const verifyResetLink = async () => {
            try {
                // eslint-disable-next-line
                const getResponse = await axios.get(`${API_URL}password-reset-confirm/${uidb64}/${token}/`);

            } catch (error) {
                let errorMessage = "Invalid reset link. Please request a new one.";

                if (error.response && error.response.status === 404) {
                    errorMessage = "The reset link has expired. Please request a new one.";
                }
                setMessage(errorMessage);
                setShowAlert(true);
                // navigate("*"); // navigate away from the password reset page
            }
        };
        if (uidb64 && token) {
            verifyResetLink();
        } else {
            navigate("*");
        }
    }, [uidb64, token, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setMessage("Passwords do not match");
            setShowAlert(true);
            return;
        }
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            // setMessage("An error occurred while resetting your password. Please try again later.");
            // setShowAlert(true);
        }, 10000);
        try {
            
            if (uidb64 && token) {
                // eslint-disable-next-line
                const response = await axios.post(`${API_URL}password-reset-confirm/${uidb64}/${token}/`, {
                    new_password: password,
                    confirm_password: confirmPassword,
                });

                setMessage("Your password has been successfully changed. Please login with your new password.");
                setShowAlert(true);
            } else {
                setMessage("Invalid reset link. Please request a new one.");
                setShowAlert(true);
            }
        } catch (error) {

            let errorMessage = `Failed to reset password. `;
            if (error.response) {
                const statusCode = error.response.status;
                errorMessage += `Error status code: ${statusCode}. `;
                switch (statusCode) {
                    case 400:
                        errorMessage = `Passwords: ${error.response.data.new_password2.join(", ")}`;
                        break;
                    case 404:
                        errorMessage = `Error message: ${error.response.data.detail}`;
                        break;
                    case 500:
                        errorMessage = `Server error: ${error.response.statusText}`;
                        break;
                    default:
                        errorMessage = `Unknown error`;
                        break;
                }
            } else if (error.request) {
                errorMessage += `Failed to reach server. Please check your internet connection.`;
            } else {
                errorMessage += `Unknown error occurred.`;
            }
            setMessage(errorMessage);
            setShowAlert(true);
        }
        setIsLoading(false);
    };

    return (
        <div>
            <div className="sign-in-area pt-70 pb-70">
                <div className="container">
                    <div className="row pt-45">
                        <div className="col-lg-12">
                            <div className="user-all-form">
                                <div className="contact-form">
                                    <div className="section-title text-center">
                                        <span className="span-bg">Reset Password</span>
                                        <h5 className="p-2">Please enter your new password below</h5>
                                    </div>
                                    <div className="container mt-3">
                                        {showAlert && (
                                            <Alert
                                                variant={message.includes("successfully") ? "success" : "danger"}
                                                onClose={() => setShowAlert(false)}
                                                dismissible
                                            >
                                                <span>{message}</span>
                                            </Alert>
                                        )}
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group controlId="formBasicPassword">
                                                <Form.Control
                                                    className="p-2 mr-2 mb-3"
                                                    type="password"
                                                    placeholder="New password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicConfirmPassword">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Confirm new password"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                            <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
                                            <Link className="forget" to="/admin_login">
                                                    Go to login
                                                </Link>
                                                <Button
                                                    className="forgot-btn"
                                                    variant="primary"
                                                    style={{ wordWrap: "break-word",border: "none" }}
                                                    type="submit"
                                                    disabled={isLoading}
                                                >
                                                    {isLoading
                                                        ? "Resetting your password..."
                                                        : "Reset Password"}
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;