import {React,useState} from "react";
import axios from "axios";
import { API_URL } from "../config";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    



const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
        setError("Passwords do not match");
        return;
    }
    try {
        const response = await axios.post(`${API_URL}register/`, 
        { username: email, email, password }
        );
        localStorage.setItem('token', response.data.token);

        setSuccess('Login successful!');
        navigate('/');
        window.location.reload();
    } catch (error) {

        if (error.response) {
            if (error.response.status === 400) {
                // handle validation errors for specific fields
                const errorData = error.response.data;
                if (errorData.username) {
                    setError(`Username: ${errorData.username}`);
                } else if (errorData.password) {
                    
                } else if (errorData.non_field_errors){
                    setError(`Error: ${errorData.non_field_errors}`);
                }else {
                    setError('Invalid credentials. Please try again.');
                }
            } else if (error.response.status === 500) {
                // handle server errors
                setError('Sorry, there was an error connecting to the server.');
            } else {
                setError('An unexpected error occurred.');
            }
        } else if (error.request) {
            setError('Unable to make a request to the server.');
        } else {
            setError('An unexpected error occurred.');
        }
    }
};

    return (
        <div>
            <div className="inner-banner inner-bg8">
                <div className="container">
                    <div className="inner-title text-center">
                        <h3> Register </h3>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <i className="bx bxs-chevron-right"></i>
                            </li>
                            <li> Register </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="sign-up-area pt-100 pb-70">
                <div className="container">
                    <div className="row pt-45">
                        <div className="col-lg-12">
                            <div className="user-all-form">
                                <div className="contact-form">
                                    <div className="section-title text-center">
                                        <span className="span-bg">Register</span>
                                        <h2>Create an Account!</h2>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        className="form-control"
                                                        required
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        placeholder="Email"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        name="password"
                                                        required
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        placeholder="Password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        name="confirmPassword"
                                                        required
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        placeholder="Confirm Password"
                                                    />
                                                </div>
                                            </div>
                                            {error && (
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <span style={{ color: "red" }}>{error}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {success && <div className="alert alert-success">{success}</div>}
                                            <div className="col-lg-12 col-md-12 text-center">
                                                <button type="submit" className="default-btn">
                                                    Register 
                                                </button>
                                            </div>
                                            <div className="col-12">
                                                <p className="account-desc">
                                                    Already have an account?&nbsp;
                                                    <Link to="/admin_login">Login</Link>
                                                </p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
