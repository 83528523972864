import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div>
            <footer className="footer-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-xxl-3 col-md-6">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <Link to="/">
                                        <img src="/assets/img/suvarnabikecare_logo2.png" className="footer-logo-one" alt="Logo" width={"auto"} height={"50px"} />
                                        <img src="/assets/img/suvarnabikecare_logo2.png" className="footer-logo-two" alt="Logo" />
                                    </Link>
                                </div>
                                <p>
                                Suvarna Bike Care, providing exceptional bike care and repair since 1992. Our values include honesty, integrity, and reliability, with a commitment to quality and customer satisfaction.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-2 col-xxl-3 col-md-6">
                            <div className="footer-widget pl-30 pl-50">
                                <h3>Useful Links</h3>
                                <ul className="footer-list">
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/services">Services</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/livevideo">Live Video</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/contact">Contact Us</Link>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-xxl-3 col-md-6">
                            <div className="footer-widget">
                                <div className="footer-widget">
                                    <h3>Address</h3>
                                    <ul className="footer-list-two">
                                        {/* <li>
                                            <i className='bx bx-time'></i>
                                            Sun - Fri: 8.00am - 6.00pm
                                        </li> */}
                                        <li>
                                            <i className='bx bx-home-smile'></i>
                                            <Link target="_blanko" to="https://goo.gl/maps/NVBLNi6aXq9hw2tz6">Suvarna Bikes Care, trimurty chowk, Bus stop, Plot 45,Sopan Bhau Kadam chowk, Durganagar, Nashik, Maharashtra 422009</Link>
                                        </li>
                                        <li>
                                            <i className='bx bx-phone'></i>
                                            <a href="tel:+919595619198">+919595619198</a>
                                        </li>
                                        {/* <li>
                                            <i className='bx bx-phone'></i>
                                            <a href="tel:+215-523-8567">+215-523-8567</a>
                                        </li> */}
                                        <li>
                                            <i className='bx bxs-envelope'></i>
                                            <a
                                                href="mailto:suvarnabikes@gmail.com"><span
                                                    className="__cf_email__"
                                                    data-cfemail="4821262e27082d32213a662b2725">suvarnabikes@gmail.com</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


            <div className="copy-right-area">
                <div className="container">
                    <div className="copy-right-text text-center">
                        <p>
                            Copyright ©&nbsp;
                            <Link style={{textDecoration: "none"}} to="/" target="_self">&nbsp;Suvarna Bike Care.</Link>&nbsp; Design and developed by 
                            <Link style={{textDecoration: "none"}} to="https://creddos.com/" target="_blank">&nbsp;Creddos.com</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
