import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../config";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin_login");
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      // eslint-disable-next-line
      const response = await axios.post(
        `${API_URL}change-password/`,
        {
          old_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      
      setShowAlert(true);
      setIsLoading(false);
      setErrorMessage("");
    } catch (error) {
      
      if (error.response) {
        if (error.response.status === 400) {
          const errorData = error.response.data;
          if (errorData.new_password) {
            setErrorMessage(`New password: ${errorData.new_password}`);
          } else if (errorData.confirm_password) {
            setErrorMessage(`Confirm password: ${errorData.confirm_password}`);
          } else if (errorData.error) {
            setErrorMessage(`Error: ${errorData.error}`);
          } else {
            setErrorMessage("Invalid input. Please try again.");
          }
        } else if (error.response.status === 401) {
          setErrorMessage("Unauthorized access. Please log in again.");
          localStorage.removeItem("token");
          navigate("/login");
        } else if (error.response.status === 500) {
          setErrorMessage("Server error. Please try again later.");
        } else {
          setErrorMessage("Unknown error occurred. Please try again later.");
        }
      } else {
        setErrorMessage("Network error. Please check your internet connection.");
      }
      setShowAlert(true);
      setIsLoading(false);
    }
  };
// eslint-disable-next-line
  const handleNewPasswordChange = (event) => {
    const newPass = event.target.value;
    setNewPassword(newPass);
    if (newPass !== confirmPassword) {
      setErrorMessage("New password and confirm password must match.");
    } else {
      setErrorMessage("");
    }
  };
  // eslint-disable-next-line
  const handleConfirmPasswordChange = (event) => {
    const confirmPass = event.target.value;
    setConfirmPassword(confirmPass);
    if (newPassword !== confirmPass) {
      setErrorMessage("New password and confirm password must match.");
    } else {
      setErrorMessage("");
    }
  };
  
  return (
    <div >
    <div className="sign-in-area pt-70 pb-70">
      <div className="container">
        <div className="row pt-45">
          <div className="col-lg-12">
            <div className="user-all-form">
              <div className="contact-form">
                <div className="section-title text-center">
                  <span className="span-bg">Change Password</span>
                  <h5 className="p-2">Enter your old and new password to change your password</h5>
                </div>
                <div className="container mt-3">
                {showAlert && (
                      <Alert
                        variant={errorMessage ? "danger" : "success"}
                        onClose={() => setShowAlert(false)}
                        dismissible
                      >
                        <span>{errorMessage ? errorMessage : "Password changed successfully!"}</span>
                      </Alert>
                    )}
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicOldPassword">
                      <Form.Label>Old Password</Form.Label>
                      <Form.Control
                        className="p-2 mr-2 mb-3"
                        type="password"
                        placeholder="Enter old password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicOldPassword">
                      <Form.Label>Old Password</Form.Label>
                      <Form.Control
                        className="p-2 mr-2 mb-3"
                        type="password"
                        placeholder="Enter New password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicOldPassword">
                      <Form.Label>Old Password</Form.Label>
                      <Form.Control
                        className="p-2 mr-2 mb-3"
                        type="password"
                        placeholder="Enter confirm password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      {/* <Link className="forget" to="/admin_login">
                        Back to login
                      </Link> */}
                      <Button
                        className=" forgot-btn"
                        variant="primary"
                        style={{ wordWrap: "break-word" ,border: "none" }}
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading
                          ? "Processing the password change..."
                          : "Change Password"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div >
  )
}

export default ChangePassword
