import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({ loggedIn, onLogout }) => {
  return (
    <div>
      <header className="top-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-9">
              <div className="header-left">
                <div className="header-left-card">
                  <ul>
                    <li>
                      <div className="head-icon">
                        <i className="bx bx-phone-call"></i>
                      </div>
                      <Link to="tel:+919595619198">+919595619198</Link>
                    </li>
                    <li>
                      <div className="head-icon">
                        <i className="bx bxs-envelope"></i>
                      </div>
                      <Link to="mailto:suvarnabikes@gmail.com" target="_blank">
                        <span
                          className="__cf_email__"
                          data-cfemail="2841464e47684d52415a064b4745"
                        >
                          suvarnabikes@gmail.com
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-3">
              <div className="header-right">
                <div className="top-social-link">
                  <ul>
                    <li>
                      <Link
                        to="https://instagram.com/suvarnabikes"
                        target="_blank"
                      >
                        <i className="bx bxl-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://api.whatsapp.com/send?phone=919595619198"
                        target="_blank"
                      >
                        <i className="bx bxl-whatsapp"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="navbar-area">
        <div className="main-nav nav-bar">
          <nav className="navbar navbar-expand-lg navbar-light ">
            <div className="container">
              <Link className="navbar-brand " to="/">
                <img
                  src="/assets/img/suvarnabikecare_logo2.png"
                  className="logo-one"
                  alt="Logo"
                  width="auto"
                  height="70px"
                />
                <img
                  src="/assets/img/suvarnabikecare_logo2.png"
                  className="logo-two"
                  alt="Logo"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" color="#fc8e41"></span>
              </button>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav m-auto">
                  <li className="nav-item">
                    <Link to="/" className="nav-link ">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about" className="nav-link">
                      About Us
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/livevideo" className="nav-link">
                      Live Video
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link to="/services" className="nav-link">
                      Services
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/contact" className="nav-link">
                      Contact Us
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="static_policy" className="nav-link">
                      Static Policy
                    </Link>
                  </li> */}
                  
                  {loggedIn ? (
                    <>
                    <li className="nav-item">
                        <Link to="/feedback" className="nav-link">
                          Feedback List
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/bike_service" className="nav-link">
                          Enquiry List
                        </Link>
                      </li>
                    {/* <li className="">
                      <Link
                        to="/admin_logout"
                        className="default-btn"
                        onClick={onLogout}
                        >
                        Logout
                      </Link>
                      
                    </li> */}
                        </>
                    
                  ) : (
                    <>
                    
                    </>
                  )}
                </ul>

                {loggedIn ? (<div> <Link
                        to="/admin_logout"
                        className="default-btn"
                        onClick={onLogout}
                        >
                        Logout
                      </Link></div>): <>  </>}
                {/* <div className="nav-btn">
                  <Link to="/" className="default-btn">
                    Appointment Now
                  </Link>
                </div> */}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
