import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_URL } from "../config";

const ForgotPass = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
             // eslint-disable-next-line
            const response = await axios.post(`${API_URL}reset-password/`, {
                email: email,
            });
            setMessage("Reset link is successfully sent to your email");
            setShowAlert(true);
            setIsLoading(false);
        } catch (error) {
            let errorMessage = `Failed to reset password. `;
            if (error.response) {
                const statusCode = error.response.status;
                errorMessage += `Error status code: ${statusCode}. `;
                switch (statusCode) {
                    case 400:
                        errorMessage = `Email: ${error.response.data.email.join(", ")}`;
                        break;
                    case 404:
                        errorMessage = `Error message: ${error.response.data.detail}`;
                        break;
                    case 500:
                        errorMessage = `Server error: ${error.response.statusText}`;
                        break;
                    default:
                        errorMessage = `Unknown error`;
                        break;
                }
            } else if (error.request) {
                errorMessage += `Failed to reach server. Please check your internet connection.`;
            } else {
                errorMessage += `Unknown error occurred.`;
            }
            setMessage(errorMessage);
            setShowAlert(true);
            setIsLoading(false);
        }
    };
    return (
        <div>
            <div className="sign-in-area pt-70 pb-70">
                <div className="container">
                    <div className="row pt-45">
                        <div className="col-lg-12">
                            <div className="user-all-form">
                                <div className="contact-form">
                                    <div className="section-title text-center">
                                        <span className="span-bg">Forgot Password</span>
                                        <h5 className="p-2">Enter your registered email address to reset your password</h5>
                                    </div>
                                    <div className="container mt-3">
                                        {showAlert && (
                                            <Alert
                                                variant={message.includes("successfully") ? "success" : "danger"}
                                                onClose={() => setShowAlert(false)}
                                                dismissible
                                            >
                                                <span>{message}</span>
                                            </Alert>
                                        )}
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control
                                                    className="p-2 mr-2 mb-3"
                                                    type="email"
                                                    placeholder="Enter email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <Link className="forget" to="/admin_login">
                                                    Back to login
                                                </Link>
                                                <Button
                                                    className="  forgot-btn "
                                                    variant="primary"
                                                    style={{ wordWrap: "break-word",border: "none"}}
                                                    type="submit"
                                                    disabled={isLoading}
                                                >
                                                    {isLoading
                                                        ? "Sending reset link to your email..."
                                                        : "Send Email"}
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPass;