import React from 'react'
import { Link } from 'react-router-dom'

const Services = () => {
    return (
        <div>
            <div className="inner-banner inner-bg12">
                <div className="container">
                    <div className="inner-title text-center">
                        <h3>Services </h3>
                        <ul>
                            <li>
                                <Link to="/home">Home</Link>
                            </li>
                            <li>
                                <i className='bx bxs-chevron-right'></i>
                            </li>
                            <li>Services </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="service-area pt-100 pb-70">
        <div className="container">
            <div className="section-title text-center">
                <span className="span-bg">Services</span>
                <h2>We are Always Ready to Serve You the Best Service</h2>
                <p>
                    We have a good expert in the global market and this will help us to make a good image on our service market and globally.
                </p>
            </div>
            <div className="row pt-45">
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/Battery.jpg" alt="Battery's"/>
                        </a>
                        <div className="content">
                            {/* <i className="flaticon-wheel"></i> */}
                            {/* <i className="flaticon-wheel service-icon-bg"></i> */}
                            <h3>Two wheeler / Four wheeler battery</h3>
                            <p>
                            Ensure your vehicle never runs out of power with our reliable and long-lasting two/four-wheeler battery.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/Door_Step_Bike_Services.jpg" alt="Door_Step_Bike_Services jpg"/>
                        </a>
                        <div className="content">
                            {/* <i className='flaticon-settings'></i> */}
                            {/* <i className="flaticon-settings service-icon-bg"></i> */}
                            <h3>Door Step Bike Services</h3>
                            <p>
                            Get doorstep bike services for hassle-free maintenance and repairs - all from the comfort of your home.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/accident.jpg" alt="Images"/>
                        </a>
                        <div className="content">
                            {/* <i className='flaticon-racing'></i> */}
                            {/* <i className="flaticon-racing service-icon-bg"></i> */}
                            <h3>Bike Accident Works</h3>
                            <p>
                            Bike Accident Works, we specialize in providing expert repair services to help you get back on the road after an accident
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/Pickup_&_Drop.jpg" alt="Images"/>
                        </a>
                        <div className="content">
                            {/* <i className='flaticon-disc-brake'></i> */}
                            {/* <i className="flaticon-disc-brake service-icon-bg"></i> */}
                            <h3>Pickup & Drop</h3>
                            <p>
                            Experience hassle-free bike servicing with our convenient pickup and drop service.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/services-img5.jpg" alt="Images"/>
                        </a>
                        <div className="content">
                            {/* <i className='flaticon-car-service-1'></i> */}
                            {/* <i className="flaticon-car-service-1 service-icon-bg"></i> */}
                            <h3>Refurbish </h3>
                            <p>
                            Revamp your bike's performance & appearance with our top-notch refurbish service.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/Insurance.jpg" alt="Images"/>
                        </a>
                        <div className="content">
                            {/* <i className='flaticon-car-service'></i> */}
                            {/* <i className="flaticon-car-service service-icon-bg"></i> */}
                            <h3>Insurance</h3>
                            <p>
                            Protect yourself and your assets with our comprehensive insurance coverage  Electric Work
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/Electric_Work.jpg" alt="Images"/>
                        </a>
                        <div className="content">
                            {/* <i className='flaticon-car-service'></i> */}
                            {/* <i className="flaticon-car-service service-icon-bg"></i> */}
                            <h3>Electric Work</h3>
                            <p>
                            We repair and install all necessary components for a seamless ride 
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/Tyres.jpg" alt="Images"/>
                        </a>
                        <div className="content">
                            {/* <i className='flaticon-racing'></i> */}
                            {/* <i className="flaticon-racing service-icon-bg"></i> */}
                            <h3>Tyres / Tubes</h3>
                            <p>
                            We provide Tyre and Tubes services for your vehicle
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/denting.jpg" alt="Images"/>
                        </a>
                        <div className="content">
                            {/* <i className='flaticon-racing'></i> */}
                            {/* <i className="flaticon-racing service-icon-bg"></i> */}
                            <h3>Denting Painting</h3>
                            <p>
                            Give your vehicle a brand new look with our expert denting and painting services
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/Emergency_Breakdown.jpg" alt="Images"/>
                        </a>
                        <div className="content">
                            {/* <i className='flaticon-racing'></i> */}
                            {/* <i className="flaticon-racing service-icon-bg"></i> */}
                            <h3>Emergency Breakdown / Engine Works</h3>
                            <p>
                            Experience a hassle-free engine service with our expert team specialized in emergence breakdown and engine works
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-card">
                        <a href="service-details.html">
                            <img src="assets/img/services/Accessories.jpg" alt="Images"/>
                        </a>
                        <div className="content">
                            {/* <i className='flaticon-racing'></i> */}
                            {/* <i className="flaticon-racing service-icon-bg"></i> */}
                            <h3>Accessories </h3>
                            <p>
                            Enhance your biking experience with our wide range of high-quality accessories for your bike
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-12 col-md-12">
                    <div className="pagination-area">
                        <a href="/#" className="prev page-numbers">
                            <i className="bx bx-chevron-left"></i>
                        </a>
                        <span className="page-numbers current" aria-current="page">1</span>
                        <a href="/#" className="page-numbers">2</a>
                        <a href="/#" className="page-numbers">3</a>
                        <a href="/#" className="next page-numbers">
                            <i className="bx bx-chevron-right"></i>
                        </a>
                    </div>
                </div> */}
            </div>
        </div>
    </div>

        </div>
    )
}

export default Services
